<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="8" md="6" sm="6" class="mt-5">
            <v-toolbar-title
              classs="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-comment-quote</v-icon
              >Supplier Quotations
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all your quotations</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>
          <v-col cols="5" md="6" sm="6" class="mt-5" align="right">
            <v-text-field
              label="Search"
              v-model="search"
              append-icon="mdi-magnify"
              dense
              small
              color="primary"
              outlined
              class="my-auto"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.quotation`]="{ item }">
        <div v-html="item.quotation"></div>
      </template>

      <template v-slot:[`item.attachment`]="{ item }">
        <a
          :href="item.attachment"
          target="_blank"
          v-if="item.attachment"
          class="text-decoration-none"
        >
          <v-icon color="primary">mdi-attachment</v-icon>
        </a>
        <a
          :href="item.attachment"
          target="_blank"
          v-else
          class="text-decoration-none"
        >
          N/A
        </a>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status.slug == 'awarded'"
          class="ma-2"
          color="success"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'pending'"
          class="ma-2"
          color="primary"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-account-clock-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'rejected'"
          class="ma-2"
          color="grey"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-close-circle</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'delivered'"
          class="ma-2"
          color="warning"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-truck-delivery-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'confirmed'"
          class="ma-2"
          color="black"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'lpo-sent'"
          class="ma-2"
          color="indigo"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'invoice-sent'" 
          class="ma-2"
          color="deep-orange darken-2"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>

        <v-chip
          v-if="item.status.slug == 'invoice-confirmed'" 
          class="ma-2"
          color="error darken-2"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-receipt</v-icon>
          </v-avatar>
          {{ item.status.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="space-around">
          <div class="my-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="viewItemModal(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>View Quotation</span>
            </v-tooltip>

            <v-tooltip top v-if="item.status.slug == 'invoice-confirmed'">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="success"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="attachDocuments(item)"
                  >
                    <v-icon>mdi-attachment</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Attach Delivery Documents</span>
            </v-tooltip>

            <v-tooltip top v-if="item.status.slug == 'lpo-sent'">
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <v-btn
                    color="indigo"
                    class="mx-1"
                    fab
                    x-small
                    dark
                    @click="generateQuotationLPO(item)"
                  >
                    <v-icon>mdi-receipt</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>View LPO</span>
            </v-tooltip>
          </div>
        </v-row>
      </template>
    </v-data-table>

    <div class="text-center">
      <v-dialog v-model="dialog_view" width="750">
        <v-card>
          <v-card-title class="headline text--white lighten-2">
            View Quotation
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.supplier.name"
                    label="Supplier Name"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Supplier Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true"
                    :config="editorConfig2"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <h3>Quotation Items</h3>

                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Quantity</th>
                            <th class="text-left">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(quotation_item, i) in editedItem.items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>{{ quotation_item.requisition_item.name }}</td>
                            <td>
                              {{ quotation_item.requisition_item.quantity }}
                            </td>
                            <td>{{ quotation_item.price }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <v-row align="center">
                    <div v-for="(item, i) in documents" :key="i">
                      <v-col cols="12" class="mt-n2">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="d-inline-block mb-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <a
                                :href="item.content"
                                target="_blank"
                                class="
                                  d-block
                                  primary--text
                                  text--darken-1 text-decoration-none
                                "
                              >
                                <v-icon x-large color="primary"
                                  >mdi-arrow-down-bold-circle</v-icon
                                >{{ truncate(item.document.name, 37) }}
                              </a>
                            </span>
                          </template>
                          <span
                            >Click to download the uploaded :
                            {{ truncate(item.document.name, 30) }}</span
                          >
                        </v-tooltip>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog_attach" width="750">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline error--text text--darken-1"
              >Attach Delivery Documents</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.requisition.title"
                    label="Requisition Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                    readonly
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Requisition Title
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Quotation Details</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.quotation"
                    shouldnotGroupWhenFull="true,"
                    :config="editorConfig"
                    disabled
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-file-input
                    v-model="editedItem.delivery_file"
                    color="grey"
                    append-icon="mdi-file-upload"
                    prepend-icon=""
                    outlined
                    dense
                    @change="handleDeliveryNoteUpload"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Attach Delivery Note
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-file-input
                    v-model="editedItem.receipt_file"
                    color="grey"
                    append-icon="mdi-file-upload"
                    prepend-icon=""
                    outlined
                    dense
                    @change="handleReceiptUpload"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Attach Receipt
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              @click="dialog_attach = false"
            >
              Close <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="success"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="supplyDelivered"
            >
              Attach <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogGenerateLPO" max-width="950px" class="pa-6">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar>
              <v-btn
                icon
                dark
                color="error"
                @click="dialogGenerateLPO = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="error--text darken-1">View Local Purchase Order</v-toolbar-title>
              <v-col class="d-flex align-right justify-end">
                <v-btn
                  color="primary darken-1"
                  fab
                  x-small
                  dark
                  @click="generateReport"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </v-col>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                  <div id="printable_content">
                    <v-row class="my-n2">
                      <v-col cols="6" sm="6" md="6">
                        <h2>Software Technologies Ltd.</h2>
                        <div>P.O Box 17797 – 00500</div>
                        <div>7th Floor, West Point Building</div>
                        <div>Mpaka Road, Westlands-Nairobi</div>
                        <div><strong>Phone:</strong> +254 709 609000</div>
                        <div><strong>Email:</strong> info@stl-horizon.com</div>
                      </v-col>

                      <v-col cols="6" sm="6" md="6">
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <h1 class="primary--text text-right">PURCHASE ORDER</h1>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-list-item-content>Date of Order</v-list-item-content>
                          </v-col>
                          <v-col cols="12" md="8">
                            <template>
                              <v-text-field
                                v-model="orderDate"
                                prepend-icon="mdi-calendar"
                                :rules="rules.required"
                                outlined
                                hide-details
                                dense
                                readonly
                              ></v-text-field>
                            </template>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-list-item-content>LPO #</v-list-item-content>
                          </v-col>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-model="editedItem.id"
                              prepend-icon="mdi-receipt"
                              outlined
                              hide-details
                              dense
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row class="my-n2">
                      <v-col cols="6" sm="6" md="6" v-if="editedItem.supplier">
                        <h2 class="primary white--text pa-2">VENDOR</h2>
                        <div>{{ editedItem.supplier.company.company_name }}</div>
                        <div>{{ editedItem.supplier.company.physical_location }}</div>
                        <div><strong>Contact Person:</strong> {{ editedItem.supplier.name }}</div>
                        <div><strong>Company Phone:</strong> {{ editedItem.supplier.company.phone_number }}</div>
                        <div><strong>Company Email:</strong> {{ editedItem.supplier.company.email_address }}</div>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" v-if="editedItem.supplier">
                        <h2 class="primary white--text pa-2">SHIP TO</h2>
                        <div>{{ editedItem.supplier.name }}</div>
                        <div><strong>Phone:</strong> {{ editedItem.supplier.phone }}</div>
                        <div><strong>Email:</strong> {{ editedItem.supplier.email }}</div>
                        <div>{{ editedItem.supplier.company.company_name }}</div>
                        <div>{{ editedItem.supplier.company.physical_location }}</div>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12" class="my-2">
                        <template>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead class="primary pa-2">
                                <tr>
                                  <th class="text-left white--text darken-1">
                                    Requisition Title
                                  </th>
                                  <th class="text-left white--text darken-1">
                                    Requisitioner
                                  </th>
                                  <th class="text-left white--text darken-1">
                                    Ship Via
                                  </th>
                                  <th class="text-left white--text darken-1">
                                    Shipping Terms
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ editedItem.lpo.requisition_title }}</td>
                                  <td>{{ editedItem.lpo.requisitioner }}</td>
                                  <td>{{ editedItem.lpo.ship_via }}</td>
                                  <td>{{ editedItem.lpo.shipping_terms }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12" class="my-2">
                        <template>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead class="primary pa-2">
                                <tr>
                                  <th class="text-left white--text darken-1" width="10%">
                                    Item #
                                  </th>
                                  <th class="text-left white--text darken-1" width="50%">
                                    Name
                                  </th>
                                  <th class="text-left white--text darken-1" width="15%">
                                    Quantity
                                  </th>
                                  <th class="text-left white--text darken-1" width="15%">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(quotation_item, i) in editedItem.items"
                                  :key="i"
                                >
                                  <td data-label="No.">{{ i + 1 }}</td>
                                  <td>{{ quotation_item.requisition_item.name }}</td>
                                  <td>{{ quotation_item.requisition_item.quantity }}</td>
                                  <td>{{ invoiceCurrency.symbol }} {{ quotation_item.price }}</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td><strong>Total Before Tax</strong></td>
                                  <td><strong>{{ decimalDigits(subTotal) }}</strong></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>Tax Total ({{ editedItem.lpo.rate }}%)</td>
                                  <td><strong>{{ decimalDigits(taxTotal) }}</strong></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td><strong>Total After Tax</strong></td>
                                  <td><strong>{{ decimalDigits(grandTotal) }}</strong></td>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          label="Comments or Special Instructions"
                          readonly
                          v-model="note"
                          name="input-7-4"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12">
                        <div class="text-center">If you have any questions about this Purchase Order, please contact</div>
                        <div class="text-center"><strong>Software Technologies Ltd. (+254 709 609000/info@stl-horizon.com)</strong></div>
                      </v-col>
                    </v-row>
                  </div>
              </v-container>
            </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                @click="closeLPO"
              >
                Cancel <v-icon small>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="sendLoading"
                @click="generateInvoiceModal(editedItem)"
              >
                Send Invoice <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>  
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogGenerateInvoice" max-width="950px" class="pa-6">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar>
              <v-btn
                icon
                dark
                color="error"
                @click="dialogGenerateInvoice = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="error--text darken-1">Generate Invoice</v-toolbar-title>
              <v-col class="d-flex align-right justify-end">
                <v-btn
                  color="primary darken-1"
                  fab
                  x-small
                  dark
                  @click="generateReport"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </v-col>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                  <div id="printable_content">
                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <h2>{{ editedItem.supplier.company.company_name }}</h2>
                        <div>{{ editedItem.supplier.company.physical_location }}</div>
                        <div><strong>Contact Person:</strong> {{ editedItem.supplier.name }}</div>
                        <div><strong>Company Phone:</strong> {{ editedItem.supplier.company.phone_number }}</div>
                        <div><strong>Company Email:</strong> {{ editedItem.supplier.company.email_address }}</div>
                        <div><strong>Website:</strong> {{ editedItem.supplier.company.website }}</div>
                      </v-col>

                      <v-col cols="6" sm="6" md="6">
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <h1 class="primary--text text-right">INVOICE</h1>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-list-item-content>Invoice Date</v-list-item-content>
                          </v-col>
                          <v-col cols="12" md="8">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu3"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="invoiceDate"
                                      label="Invoice Date"
                                      prepend-icon="mdi-calendar"
                                      :rules="rules.required"
                                      readonly
                                      hide-details
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="invoiceDate"
                                    @input="menu3 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-list-item-content>Invoice #</v-list-item-content>
                          </v-col>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-model="editedItem.id"
                              prepend-icon="mdi-receipt"
                              outlined
                              hide-details
                              dense
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="4">
                            <v-list-item-content>Due Date</v-list-item-content>
                          </v-col>
                          <v-col cols="12" md="8">
                            <template>
                              <div>
                                <v-menu
                                  v-model="menu4"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="dueDate"
                                      :rules="rules.required"
                                      label="Due Date"
                                      dense
                                      outlined
                                      prepend-icon="mdi-calendar"
                                      hide-details
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dueDate"
                                    @input="menu4 = false"
                                  ></v-date-picker>
                                </v-menu>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" sm="6" md="6">
                        <h2 class="primary white--text pa-2">BILL TO</h2>
                        <div>Software Technologies Ltd.</div>
                        <div>P.O Box 17797 – 00500</div>
                        <div>7th Floor, West Point Building</div>
                        <div>Mpaka Road, Westlands-Nairobi</div>
                        <div><strong>Phone:</strong> +254 709 609000</div>
                        <div><strong>Email:</strong> info@stl-horizon.com</div>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" v-if="editedItem.lpo">
                        <h2 class="primary white--text pa-2">SHIP TO</h2>
                        <div>Software Technologies Ltd.</div>
                        <div>P.O Box 17797 – 00500</div>
                        <div>7th Floor, West Point Building</div>
                        <div>Mpaka Road, Westlands-Nairobi</div>
                        <div><strong>Contact Person:</strong> {{ editedItem.lpo.requisitioner }}</div>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12" class="my-2">
                        <template>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead class="primary pa-2">
                                <tr>
                                  <th class="text-left white--text darken-1" width="10%">
                                    Item #
                                  </th>
                                  <th class="text-left white--text darken-1" width="50%">
                                    Name
                                  </th>
                                  <th class="text-left white--text darken-1" width="15%">
                                    Quantity
                                  </th>
                                  <th class="text-left white--text darken-1" width="15%">
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(quotation_item, i) in editedItem.items"
                                  :key="i"
                                >
                                  <td data-label="No.">{{ i + 1 }}</td>
                                  <td>{{ quotation_item.requisition_item.name }}</td>
                                  <td>{{ quotation_item.requisition_item.quantity }}</td>
                                  <td>{{ invoiceCurrency.symbol }} {{ quotation_item.price }}</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td><strong>Total Before Tax</strong></td>
                                  <td><strong>{{ decimalDigits(subTotal) }}</strong></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <v-text-field
                                      v-model="discountRate"
                                      label="Discount"
                                      :rules="rules.items"
                                      color="grey"
                                      dense
                                      outlined
                                      type="number"
                                      class="mb-n3"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label"
                                          >Discount %
                                          <v-icon small color="error" class="mt-n2"
                                            >mdi-star-outline</v-icon
                                          ></span
                                        >
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td><strong>{{ decimalDigits(discountTotal) }}</strong></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>Tax Total ({{ editedItem.lpo.rate }}%)</td>
                                  <td><strong>{{ decimalDigits(taxTotal) }}</strong></td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td><strong>Total After Tax</strong></td>
                                  <td><strong>{{ decimalDigits(grandTotal) }}</strong></td>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12">
                        <ol>
                          <li>Total payment due on {{ dueDate }}.</li>
                          <li>Please include the invoice number on your cheque.</li>
                        </ol>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          label="Other Comments"
                          v-model="comments"
                          name="input-7-4"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row class="my-2">
                      <v-col cols="12" sm="12" md="12">
                        <div class="text-center">If you have any questions about this Invoice, please contact</div>
                        <div class="text-center" v-if="editedItem.supplier"><strong>{{ editedItem.supplier.name }} ({{ editedItem.supplier.phone }}/{{ editedItem.supplier.email }})</strong></div>
                      </v-col>
                    </v-row>
                  </div>
              </v-container>
            </v-card-text>
      
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                @click="closeLPO"
              >
                Cancel <v-icon small>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="sendLoading"
                @click="sendInvoice"
              >
                Send Invoice <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>  
        </v-card>
      </v-dialog>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import QuotationService from "../../services/quotation.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { VueHtml2pdf },
  data: () => ({
    name: "SupplierCategory",
    workflows: [],
    supplier_groups: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    editor: ClassicEditor,
    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },
    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    dialogGenerateLPO: false,
    dialogGenerateInvoice: false,
    sendLoading: false,
    items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      attachment: "",
      delivery: "",
      invoice: "",
      receipt: "",
      delivery_file: [],
      invoice_file: [],
      receipt_file: [],
      requisition: {
        title: "",
      },
      supplier: {
        name: "",
        company: {
          company_name: "",
        },
      },
    },
    defaultItem: {
      attachment: "",
      delivery: "",
      invoice: "",
      receipt: "",
      delivery_file: [],
      invoice_file: [],
      receipt_file: [],
      requisition: {
        title: "",
      },
      supplier: {
        name: "",
        company: {
          company_name: "",
        },
      },
    },
    remember: 0,
    timeout: 2000,
    orderDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    validDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    menu3: false,
    menu4: false,
    invoiceDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dueDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    note: "",
    comments: "",
    dialog: false,
    valid: true,
    dialog_attach: false,
    documents: [],
    deliveryNote: {},
    deliveryInvoice: {},
    deliveryReceipt: {},

    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Requisition Title",
        value: "requisition.title",
      },
      {
        text: "Supplier Name",
        value: "supplier.name",
      },
      {
        text: "Quotation",
        value: "quotation",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
        width: "20%",
      },
    ],
    document_headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Quotation Document",
        value: "attachment",
      },
      {
        text: "Delivery Note",
        value: "delivery",
      },
      {
        text: "Invoice",
        value: "invoice",
      },
      {
        text: "Receipt",
        value: "receipt",
      },
      {
        text: "Goods Received Note",
        value: "goods",
      },
      {
        text: "Payment Voucher",
        value: "voucher",
      },
    ],
    invoiceCurrency: {
      symbol: "Ksh",
      name: "Kenyan Shilling",
      symbol_native: "Ksh",
      decimal_digits: 2,
      rounding: 0,
      code: "KES",
      name_plural: "Kenyan shillings",
    },
    taxRate: 16,
    discountRate: 0,
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    subTotal() {
      var sum = 0;
      if (this.editedItem.items) {
        this.editedItem.items.forEach((item) => {
          sum = sum += item.price;
        });
      }

      return sum;
    },
    discountTotal() {
      var total = this.subTotal * (this.discountRate / 100);
      return total;
    },
    taxTotal() {
      var total = (this.subTotal - this.discountTotal) * (this.taxRate / 100);
      return total;
    },
    grandTotal() {
      var total = this.subTotal - this.discountTotal + this.taxTotal;
      return total;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
  },
  methods: {
    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getQuotationDocuments(this.editedItem);
      this.dialog_view = true;
    },

    generateQuotationLPO(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.note = this.editedItem.lpo.note;
      this.dialogGenerateLPO = true;
    },

    generateInvoiceModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogGenerateInvoice = true;
      this.dialogGenerateLPO = false;
    },

    attachDocuments(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_attach = true;
      console.log(item);
    },

    decimalDigits: function (value) {
      return (
        this.invoiceCurrency.symbol +
        " " +
        value.toFixed(this.invoiceCurrency.decimal_digits)
      );
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },

    printInvoice() {
      var printable_content = document.getElementById("printable_invoice_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },

    sendInvoice() {
      this.loading = true;
      this.sendLoading = true;
      this.$refs.form.validate();

      this.editedItem.price = this.subTotal;
      this.editedItem.discount = this.discountTotal;
      this.editedItem.rate = this.discountRate;
      this.editedItem.tax = this.taxTotal;
      this.editedItem.total = this.grandTotal;
      this.editedItem.due = this.dueDate;
      this.editedItem.invoice = this.invoiceDate;
      this.editedItem.comments = this.comments;
      
      if (this.valid) {
          // create
          QuotationService.send_invoice(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", "LPO Sent Successfully");
                console.log(this.editedItem);
                this.loading = false;
                this.sendLoading = false;
                this.index();
                this.closeInvoice();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error.response.data.message);
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }

              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
      }
    },

    index() {
      return QuotationService.supplier_index(this.user).then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            console.log(this.items);
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    getQuotationDocuments(item) {
      this.loading = true;
      return QuotationService.get_documents(item).then(
        (response) => {
          if (response.status == 200) {
            this.documents = response.data.data;
            this.loading = false;
          } else {
            this.documents = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.documents = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialog_attach = false;
    },

    closeLPO() {
      this.dialogGenerateLPO = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeInvoice() {
      this.dialogGenerateInvoice = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleDeliveryNoteUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = 3;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      this.deliveryNote = formData;
    },

    async handleInvoiceUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = 4;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      this.deliveryInvoice = formData;
    },

    async handleReceiptUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = 5;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      this.deliveryReceipt = formData;
    },

    supplyDelivered() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.editedItem.delivery_note = this.deliveryNote;
        this.editedItem.invoice = this.deliveryInvoice;
        this.editedItem.receipt = this.deliveryReceipt;

        console.log(this.editedItem);
        QuotationService.delivered(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.index();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
